<template>
  <div>
    <el-header style="height: 60px">
      <div class="left-header">
        <img class="topbar-logo" src="@/assets/logo.png" />
        <div class="head-header">
          <img class="name_logo" src="@/assets/name_logo.png" alt="" />
        </div>
      </div>
      <div
        style="
          position: absolute;
          top: 0px;
          right: 60px;
          color: white;
          cursor: pointer;
          display: flex;
          align-items: center;
          height: 60px;
        "
        @click="to_web"
      >
        <div
          style="
            color: white;
            border: 1px solid white;
            font-size: 16px;
            padding: 5px 10px;
            border-radius: 5px;
          "
        >
          <i class="iconfont icon-a-fenzu21" style="margin-right: 10px"></i>
          {{ $t("share.know_1") }}
        </div>
      </div>
      <language class="language-option"></language>
    </el-header>
    <el-collapse-transition v-if="show_file">
      <div v-show="show_top" class="header">
        <div class="topic" id="topic_key_topic">
          <div style="display: flex; min-width: 200px" id="topic_key">
            <div class="box_item_l">
              <el-tooltip
                class="item"
                effect="dark"
                :content="file_icon_name"
                placement="top"
              >
                <div style="background-color: #014caa">
                  <i
                    v-if="file_icon_icon !== '-1'"
                    class="iconfont"
                    :class="'icon-a-' + file_icon_icon"
                    style="color: #fff"
                  ></i>
                  <div v-else style="color: white; font-weight: 700">
                    {{ file_icon_name.slice(0, 1) }}
                  </div>
                </div>
              </el-tooltip>
            </div>
            <div class="box_item_r">
              <div
                class="box_font"
                style="cursor: pointer"
                @click="jump_node(file_icon_key)"
              >
                {{ file_icon_key }}
              </div>
            </div>
          </div>
          <div
            id="topic_topic"
            style="
              margin: 0 auto;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              text-align: left;
              width: calc(100% - 200px);
            "
          >
            {{ topic }}
          </div>
        </div>
        <div class="operate">
          <el-menu
            :default-active="activeIndex"
            text-color="#333"
            class="el-menu-demo"
            mode="horizontal"
            style="border: none !important"
          >
            <el-submenu index="1" style="line-height: normal; height: auto">
              <template slot="title" style="color: red">
                <span id="topbar-view">
                  <i class="iconfont icon-shitu" style="margin-right: 10px"></i>
                  {{ $t("canvas.btn.view") }}
                </span>
              </template>
              <el-menu-item
                @click.native="handle_state_outstanding_click"
                index="1-1"
              >
                <span>{{ $t("canvas.viewDropDown.showStatus") }}</span>
                <!-- <span
                  :style="{
                    color: 'rgb(144, 150, 159)',
                    float: 'right',
                    fontSize: '12px',
                  }"
                >
                  {{ showShortcutkeys("showStatus_shortcutKey") }}
                </span> -->
              </el-menu-item>

              <!-- 其他字段 -->
              <el-submenu index="1-2">
                <template slot="title">
                  {{ $t("node.message.message6") }}
                </template>
                <el-menu-item
                  v-for="value in otherFile_list"
                  :key="value[0]"
                  @click.native="handle_state_otherFile_click(value[0])"
                  >{{ value[1].name }}</el-menu-item
                >
              </el-submenu>

              <el-menu-item @click.native="to_word" index="1-3">
                <span>{{ $t("share.word") }}</span>
                <!-- <span
                  :style="{
                    color: 'rgb(144, 150, 159)',
                    float: 'right',
                    fontSize: '12px',
                  }"
                >
                  {{ showShortcutkeys("showStatus_shortcutKey") }}
                </span> -->
              </el-menu-item>
            </el-submenu>

            <el-menu-item
              @click.native="zoombig"
              index="2"
              style="line-height: normal; height: auto"
            >
              <el-tooltip class="item" effect="dark" placement="bottom">
                <div slot="content">
                  {{ $t("canvas.tip1") }}
                </div>
                <!-- 放大icon -->
                <i class="iconfont icon-add" style="font-size: 20px"></i>
              </el-tooltip>
            </el-menu-item>
            <el-menu-item
              @click.native="zoomsmall"
              index="3"
              style="line-height: normal; height: auto"
            >
              <el-tooltip class="item" effect="dark" placement="bottom">
                <div slot="content">
                  {{ $t("canvas.tip2") }}
                </div>
                <!-- 缩小icon -->
                <i class="iconfont icon-narrow" style="font-size: 20px"></i>
              </el-tooltip>
            </el-menu-item>

            <el-menu-item
              @click.native="full"
              index="4"
              style="line-height: normal; height: auto"
            >
              <el-tooltip class="item" effect="dark" placement="bottom">
                <div slot="content">
                  {{ $t("canvas.tip6") }}
                </div>
                <!-- 全屏icon -->
                <i class="iconfont icon-fullscreen"></i>
              </el-tooltip>
            </el-menu-item>
          </el-menu>
          <!-- <div class="word" style="cursor: pointer" @click="to_word">
            
            {{ $t("share.word") }}
          </div> -->

          <div v-if="!is_login" class="save">
            <el-button
              type="primary"
              style="text-align: center"
              size="mini"
              @click="to_login"
            >
              {{ $t("share.share_down") }}
            </el-button>
          </div>
          <div v-else class="save">
            <el-button
              type="primary"
              style="text-align: center"
              size="mini"
              @click="to_login"
              >{{ $t("share.save") }}</el-button
            >
          </div>
        </div>
      </div>
    </el-collapse-transition>
    <div v-if="show_file">
      <div v-if="show_top" class="switch_on" @click="show_top = !show_top">
        <i class="iconfont icon-fold_up" style="color: #000" />
        {{ $t("switch.PutAway") }}
      </div>
      <div v-if="!show_top" class="switch_off" @click="show_top = !show_top">
        <i class="iconfont icon-fold_down" style="color: #000" />
        {{ $t("switch.Expand") }}
      </div>
      <!-- myDiv 计算文本宽度高度 -->
      <div id="myDiv"></div>
      <!-- myCanvas 也来计算文本宽度高度 -->
      <canvas id="myCanvas" style="display: none"></canvas>
      <div class="body">
        <div
          id="label_container2"
          :style="show_top ? 'height: 90vh;' : 'height: 99vh;'"
        >
          <div id="container" style="height: 100%; width: 100%"></div>
        </div>
      </div>
      <div
        id="minicontainer"
        style="position: fixed; z-index: 99; bottom: 0; right: 0"
      ></div>
    </div>
    <div v-if="!show_file" class="nofile">
      <div>
        <p>{{ $t("share.s1") }}</p>
        <el-button type="primary" size="small" @click="notice">{{
          $t("share.s2")
        }}</el-button>
      </div>
    </div>
    <el-dialog
      :title="$t('share.save')"
      width="25%"
      :close-on-click-modal="false"
      :show-close="true"
      :visible.sync="saveDialogVisible"
      :before-close="close_dai"
    >
      <el-select
        v-model="select_project"
        :placeholder="$t('share.pro')"
        style="width: 100%; margin-bottom: 25px"
      >
        <el-option
          v-for="item in project_options"
          :key="item.projectId"
          :label="item.projectName"
          :value="item.projectId"
        >
        </el-option>
      </el-select>

      <el-select
        v-if="select_project.length > 0"
        v-model="select_file"
        :placeholder="$t('share.file')"
        style="width: 100%; margin-bottom: 25px"
      >
        <el-option
          v-for="item in file_options"
          :key="item.fileTypeId"
          :label="item.name"
          :value="item.fileTypeId"
        >
        </el-option>
      </el-select>

      <span slot="footer" class="dialog-footer">
        <el-button type="info" size="small" @click="close_dai">{{
          $t("btn.cancelBtn")
        }}</el-button>
        <el-button type="primary" size="small" @click="save_file">{{
          $t("btn.confirmBtn")
        }}</el-button>
      </span>
    </el-dialog>

    <!-- 是否已有账户 -->
    <el-dialog
      :title="$t('addMindMap.saveMindmapMoveDialogTitle')"
      width="25%"
      :close-on-click-modal="false"
      :show-close="true"
      :visible.sync="moveDialogVisible"
    >
      <span slot="footer" class="dialog-footer">
        <el-button type="info" size="small" @click="hasAccount">{{
          $t("addMindMap.hasAccount")
        }}</el-button>
        <el-button type="primary" size="small" @click="noAccount">{{
          $t("addMindMap.noAccount")
        }}</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :title="$t('share.ti')"
      width="25%"
      :close-on-click-modal="false"
      :show-close="true"
      :visible.sync="tipDialogVisible"
    >
      <div style="font-size: 16px; text-align: start">
        {{ tip_text }}
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="info" size="small" @click="tipDialogVisible = false">{{
          $t("btn.cancelBtn")
        }}</el-button>
        <el-button type="primary" size="small" @click="to_login">{{
          $t("btn.saveBtn")
        }}</el-button>
      </span>
    </el-dialog>

    <div v-show="show_process" id="loading_share">
      <img class="topbar-logo" :src="logoUrl" style="width: 10%" />
      <el-progress
        style="width: 50%; margin-top: 50px"
        :percentage="percent"
        :color="customColorMethod"
        :stroke-width="15"
        :show-text="false"
      ></el-progress>
      <div style="width: 50%; margin-top: 50px; font-size: 20px">
        加载进度： {{ parseInt(percent) }} %
      </div>
    </div>

    <!-- 导出图像 -->
    <el-dialog
      v-if="show_export_png_dialog"
      :title="$t('canvas.fileDropDown.export') + 'Png'"
      :close-on-click-modal="false"
      :show-close="false"
      :visible.sync="show_export_png_dialog"
      top="10vh"
      style="visibility: hidden"
    >
      <export-png></export-png>
    </el-dialog>

    <el-dialog
      v-if="outstanding_detail_dialog_show"
      id="outstanding_detail_dialog"
      v-draggable
      class="outstanding_detail_dialog"
      custom-class="outstanding_detail_dialog_body"
      :visible.sync="outstanding_detail_dialog_show"
      :title="outstanding_name"
      width="260px"
      :modal="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="clear_reset"
    >
      <el-table
        ref="outstanding_dialog_table_word"
        :data="modify_table_data(outstanding_map)"
        class="outstanding_dialog_table_class"
        :show-header="false"
        cell-class-name="outstanding_dialog_table_class_cell"
      >
        <el-table-column prop="name" show-overflow-tooltip>
          <template slot-scope="scope">
            <div class="outstanding_name">
              {{ scope.row.name }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="color" width="30">
          <template slot-scope="scope">
            <div
              class="outstanding_dialog_color"
              :style="'background-color:#' + scope.row.color"
            ></div>
          </template>
        </el-table-column>
        <el-table-column prop="num" width="50"> </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
// import { getFile } from "@/network/home/index.js";
import { Graph, DataUri } from "@antv/x6";
import Hierarchy from "@antv/hierarchy";
import { getToken } from "@/utils/auth";
import language from "@/components/language";
import { request_joinedProjects } from "@/network/personal/index.js";
import { get_filetype_List } from "@/network/fileType/index.js";
import logo_light from "@/assets/logo_small.png";
import get_water from "@/store/water.js";
import {
  getFile,
  save_file,
  get_status,
  viewRequestNotify,
} from "@/network/share/index.js";
import exportPng from "@/components/exportPng/index.vue";
import vmson from "@/utils/vmson";

export default {
  components: {
    language,
    exportPng,
  },
  data() {
    return {
      file_icon_name: "系统",
      file_icon_key: "系统",
      file_icon_icon: "系统",
      activeIndex: "1",
      show_top: true,
      show_file: true,
      topic: "",
      graph: "",
      node_width: 450,
      node_padding_width: 20,
      tipDialogVisible: false,
      y: 0,
      saveDialogVisible: false,
      project_options: [],
      show_export_png_dialog: false,
      select_project: "",
      select_file: "",
      file_options: [],
      tip_text: "",
      is_over3: false,
      show_process: false,
      percent: 0,
      all_num: 0,
      export_png_loading: "",
      moveDialogVisible: false,
      is_login: false,
      water_area: [0, 0, 0, 0],
      isFull: true,
      otherFile_list: [],
      pid: "",
      node_data: [],
      outstanding_map: [],
      outstanding_detail_dialog_show: false,
      outstanding_name: "",
    };
  },
  computed: {
    logoUrl() {
      return logo_light;
    },
  },
  watch: {
    select_project() {
      get_filetype_List(this.select_project).then((res) => {
        this.file_options = res;
        this.select_file = "";
      });
    },
  },
  created() {
    let system = {
      win: false,
      mac: false,
      xll: false,
    };
    let p = navigator.platform;
    system.win = p.indexOf("Win") == 0;
    system.mac = p.indexOf("Mac") == 0;
    system.x11 = p == "X11" || p.indexOf("Linux") == 0;
    if (!(system.win || system.mac || system.xll)) {
      this.$router.push({
        path: `/m/pubMindmap/` + this.$route.params.pathId,
      });
    }

    function dd3(dateString) {
      const targetDate = new Date(dateString);
      const currentDate = new Date();
      const diff = currentDate - targetDate;
      const daysDiff = Math.ceil(diff / (1000 * 60 * 60 * 24)); // 毫秒转为天，向上取整
      return daysDiff > 3;
    }

    this.is_login = getToken() ? true : false;

    get_status(this.$route.params.pathId).then((data) => {
      let is_read =
        sessionStorage.getItem(data.fileKey) &&
        sessionStorage.getItem(data.fileKey) == "has";
      if (data.effective && data.settingEffective) {
        getFile(this.$route.params.pathId).then((res) => {
          this.node_data = res.data;
          this.file_icon_name = res.fileTypeName;
          this.file_icon_icon = res.fileTypeIcon;
          this.pid = res.projectId;
          this.file_icon_key = res.meta.key;
          // setTimeout(() => {
          //   document.getElementById("topic_topic").style.width =
          //     document.getElementById("topic_key_topic").clientWidth -
          //     document.getElementById("topic_key").clientWidth -
          //     20 +
          //     "px";
          //   console.log(document.getElementById("topic_topic").style.width);
          // }, 1000);
          if (dd3(data.createdAt) && !is_read) {
            this.tip_text = this.$t("share.d3");
            sessionStorage.setItem(data.fileKey, "has");
            this.is_over3 = true;
            this.tipDialogVisible = true;
          }
          this.topic = res.meta.name;
          this.all_num = res.data.length;
          if (res.data.length > 300) {
            this.show_process = true;
          }
          let data_ = new Map();
          res.data.forEach((item1) => {
            item1.content.forEach((item) => {
              if (
                item.fieldType == "SINGLE_USER_SELECT" ||
                item.fieldType == "SELECT"
              ) {
                if (data_.has(item.fieldId)) {
                  if (item.value == null) {
                    let arr = [...data_.get(item.fieldId).noId];
                    arr.push(item1.id);
                    data_.set(item.fieldId, {
                      name: item.name,
                      noNum: data_.get(item.fieldId).noNum + 1,
                      noId: arr,
                      hasNum: data_.get(item.fieldId).hasNum,
                      hasId: data_.get(item.fieldId).hasId,
                    });
                  } else {
                    let arr = [...data_.get(item.fieldId).hasId];
                    arr.push(item1.id);
                    data_.set(item.fieldId, {
                      name: item.name,
                      noNum: data_.get(item.fieldId).noNum,
                      noId: data_.get(item.fieldId).noId,
                      hasNum: data_.get(item.fieldId).hasNum + 1,
                      hasId: arr,
                    });
                  }
                } else {
                  if (item.value == null) {
                    data_.set(item.fieldId, {
                      name: item.name,
                      noNum: 1,
                      noId: [item1.id],
                      hasNum: 0,
                      hasId: [],
                    });
                  } else {
                    data_.set(item.fieldId, {
                      name: item.name,
                      noNum: 0,
                      noId: [],
                      hasNum: 1,
                      hasId: [item1.id],
                    });
                  }
                }
              }
            });
          });
          this.otherFile_list = data_;
          console.log(data_);
          setTimeout(() => {
            this.init(res.data);
          }, 1000);
        });
      } else {
        this.show_file = false;
      }
    });
  },
  mounted() {
    vmson.$on("export_png_success", () => {
      this.show_export_png_dialog = false;
      // this.export_png_loading.close();
    });
  },
  methods: {
    clear_reset() {
      for (let n of this.node_data) {
        document.getElementById("node@" + n.id).style.background = "none";
        document.getElementById("node@" + n.id).style.color = "black";
      }
      this.outstanding_detail_dialog_show = false;
    },
    handle_state_outstanding_click() {
      this.outstanding_map = {};
      let result_map = {};

      for (let n of this.node_data) {
        document.getElementById("node@" + n.id).style.background = "none";
        document.getElementById("node@" + n.id).style.color = "black";

        if (n["status"] in result_map) {
          result_map[n["status"]] += 1;
        } else {
          result_map[n["status"]] = 1;
        }
      }

      let color_map = [
        "E87A90",
        "eeb8c3",
        "813c85",
        "0f59a4",
        "101f30",
        "134857",
        "1ba784",
        "248067",
        "8cc269",
        "fed71a",
        "835e1d",
        "f26b1f",
        "cf4813",
        "862617",
      ];
      for (let i of Object.keys(result_map)) {
        this.outstanding_map[i] = {
          name: i,
          color: color_map.shift(),
          num: result_map[i],
          val: i,
        };
      }
      for (let n of this.node_data) {
        document.getElementById("node@" + n.id).style.background =
          "#" + this.outstanding_map[n["status"]].color;
        document.getElementById("node@" + n.id).style.color = "white";
      }
      this.outstanding_name = this.$t("canvas.wordView.status");
      this.outstanding_detail_dialog_show = true;
    },
    handle_state_otherFile_click(fieldId) {
      this.outstanding_map = {};

      let otherFile_map = {};
      let result_map = {};

      for (let n of this.node_data) {
        document.getElementById("node@" + n.id).style.background = "none";
        document.getElementById("node@" + n.id).style.color = "black";

        let flag = true;
        for (let i of n.content) {
          if (i.fieldId == fieldId) {
            this.outstanding_name = i.name;
            flag = false;
            otherFile_map[n.id] = i.value;
            if (i.value in result_map) {
              result_map[i.value] += 1;
            } else {
              result_map[i.value] = 1;
            }
          }
        }
        if (flag) {
          otherFile_map[n.id] = this.$t("node.message.message1");
          if (this.$t("node.message.message1") in result_map) {
            result_map[this.$t("node.message.message1")] += 1;
          } else {
            result_map[this.$t("node.message.message1")] = 1;
          }
        }
      }
      let color_map = [
        "f07c82",
        "5c2223",
        "eeb8c3",
        "813c85",
        "0f59a4",
        "101f30",
        "134857",
        "1ba784",
        "248067",
        "8cc269",
        "fed71a",
        "835e1d",
        "f26b1f",
        "cf4813",
        "862617",
      ];
      for (let i of Object.keys(result_map)) {
        if (i != "null") {
          this.outstanding_map[i] = {
            name: i,
            color: color_map.shift(),
            num: result_map[i],
            val: i,
          };
        }
      }
      if ("null" in result_map) {
        this.outstanding_map["null"] = {
          name: this.$t("canvas.wordView.null"),
          color: "5f5e5f",
          num: result_map["null"],
          val: null,
        };
      }
      for (let n of this.node_data) {
        document.getElementById("node@" + n.id).style.background =
          "#" + this.outstanding_map[otherFile_map[n.id]].color;
        document.getElementById("node@" + n.id).style.color = "white";
      }
      this.outstanding_detail_dialog_show = true;
    },
    zoombig() {
      this.graph.zoom(0.1);
    },
    zoomsmall() {
      this.graph.zoom(-0.1);
    },
    jump_node(item) {
      const { href } = this.$router.resolve({
        path: `/${this.pid}/myMind/${item}`,
      });
      window.open(href, "_blank");
    },
    modify_table_data(data) {
      let l = [];
      let null_flag = null;
      for (let i of Object.keys(data)) {
        if (data[i].color == "5f5e5f") {
          null_flag = data[i];
        } else {
          l.push(data[i]);
        }
      }
      l.sort(function (a, b) {
        return b.num - a.num;
      });
      if (null_flag) {
        l.push(null_flag);
      }
      return l;
    },
    full() {
      if (this.isFull) {
        let docElm = document.documentElement;
        if (docElm.requestFullscreen) {
          docElm.requestFullscreen();
        } else if (docElm.mozRequestFullScreen) {
          docElm.mozRequestFullScreen();
        } else if (docElm.webkitRequestFullScreen) {
          docElm.webkitRequestFullScreen();
        } else if (docElm.msRequestFullscreen) {
          docElm.msRequestFullscreen();
        }
        this.isFull = false;
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
        this.isFull = true;
      }
    },
    add_water_mask() {
      this.water_area[0];

      let bas = this.get_water_base64(this.$store.getters.name);
      let that = this;

      setTimeout(() => {
        let ddd = {
          x: this.water_area[0],
          y: this.water_area[2],
          width: this.water_area[1] - this.water_area[0],
          height: this.water_area[3] - this.water_area[2],
          shape: "html",
          id: "water_mask_share",
          zIndex: 1,
          html() {
            const con = document.createElement("div"); //

            con.style.width = that.water_area[1] - that.water_area[0] + "px";
            con.style.height = that.water_area[3] - that.water_area[2] + "px";
            con.style.backgroundImage = `url(${bas})`;
            con.style.backgroundRepeat = "repeat";

            return con;
          },
        };
        this.graph.addNode(ddd);
      }, 0);
    },
    get_water_base64(text) {
      text = "Mappingspace  " + text;
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      let width = this.calculate_node_width_height(text);
      let height = this.new_calculate_node_width_height(text);

      const fontSize = 18;
      const fontFamily = "Arial";
      const textColor = "#e2e2e2";
      const textAlign = "center";
      canvas.width = Math.max(width, height);
      canvas.height = Math.max(width, height) + 100;

      ctx.rotate((25 * Math.PI) / 180);

      ctx.font = `${fontSize}px ${fontFamily}`;
      ctx.fillStyle = textColor;
      ctx.textAlign = textAlign;

      ctx.fillText(text, width / 2 + 10, height / 2);

      return canvas.toDataURL("image/png");
    },
    to_web() {
      window.open("https://www.ytdevops.com/");
    },
    download() {
      this.add_water_mask();
      setTimeout(() => {
        this.graph.toPNG(
          (dataUri) => {
            let canvas = document.createElement("canvas");
            let context = canvas.getContext("2d");
            context.imageSmoothingEnabled = true;
            context.imageSmoothingQuality = 1;

            let base64Img = new Image();
            base64Img.src = dataUri;
            base64Img.onload = () => {
              let watermarkImg = new Image();
              watermarkImg.src = get_water();
              watermarkImg.onload = () => {
                let scale = 2;
                let h = base64Img.height * 0.1;
                let w = (watermarkImg.width * h) / watermarkImg.height;
                canvas.width = Math.max(base64Img.width, w) * scale;
                canvas.height = (base64Img.height + h) * scale;

                context.fillStyle = "white";
                context.fillRect(0, 0, canvas.width, canvas.height);

                context.drawImage(
                  base64Img,
                  0,
                  0,
                  base64Img.width * scale,
                  base64Img.height * scale
                );

                context.imageSmoothingEnabled = true;
                context.imageSmoothingQuality = 1;

                let y = base64Img.height * scale;

                context.drawImage(watermarkImg, 0, y, w * scale, h * scale);

                context.imageSmoothingEnabled = true;
                context.imageSmoothingQuality = 1;

                let resultImg = canvas.toDataURL("image/png");
                DataUri.downloadDataUri(resultImg, this.topic + ".png");
                vmson.$emit("export_png_success");

                setTimeout(() => {
                  this.graph.removeNode(`water_mask_share`);
                }, 0);
              };
            };
          },
          {
            backgroundColor: "white",
            quality: 1,
            padding: {
              top: 20,
              right: 50,
              bottom: 20,
              left: 50,
            },
          }
        );
      }, 0);
    },
    exportImg() {
      this.show_export_png_dialog = true;
      // this.export_png_loading = this.$loading({
      //   lock: true,
      //   text: "Loading",
      //   spinner: "el-icon-loading",
      //   background: "rgba(0, 0, 0, 0.7)",
      // });
    },
    customColorMethod(percentage) {
      return "rgb(0,76,170)";
      // if (percentage < 30) {
      //   return "#909399";
      // } else if (percentage < 70) {
      //   return "#e6a23c";
      // } else {
      //   return "#67c23a";
      // }
    },
    close_dai() {
      this.saveDialogVisible = false;
      this.select_project = "";
      this.select_file = "";
    },
    notice() {
      if (getToken()) {
        viewRequestNotify(this.$route.params.pathId).then((res) => {
          this.$message({
            message: this.$t("share.send"),
            type: "success",
            duration: "5000",
          });
        });
      } else {
        sessionStorage.setItem("backURL", this.$route.path);

        this.$router.push({
          path: `/login`,
        });
      }
    },
    save_file() {
      if (this.select_project.length < 1) {
        this.$message({
          message: this.$t("share.pro"),
          type: "error",
          duration: "5000",
        });
        return;
      }
      if (this.select_file.length < 1) {
        this.$message({
          message: this.$t("share.file"),
          type: "error",
          duration: "5000",
        });
        return;
      }
      let da = {
        createFileDto: {
          fileTypeId: this.select_file,
          name: this.topic,
          testCase: "",
          testPlanId: "",
        },
        shareId: this.$route.params.pathId,
      };
      save_file(this.select_project, da).then((re) => {
        this.$router.push({
          path: `/${this.select_project}/myMind/${re}`,
        });
      });
    },
    to_login() {
      this.tipDialogVisible = false;
      if (getToken()) {
        request_joinedProjects().then((res) => {
          this.project_options = res;
          this.saveDialogVisible = true;
        });
      } else {
        sessionStorage.setItem("backURL", this.$route.path);
        this.moveDialogVisible = true;
      }
    },
    hasAccount() {
      sessionStorage.setItem("exportPng", this.$route.params.pathId);
      this.$router.push({
        path: `/login`,
      });
    },
    noAccount() {
      sessionStorage.setItem("exportPng", this.$route.params.pathId);
      this.$router.push({
        path:
          `/login/register?inviteMark=share-` + `${this.$route.params.pathId}`,
      });
    },
    to_word() {
      this.$router.push({
        path: `/pubWordview/` + this.$route.params.pathId,
      });
    },
    calculate_node_width_height(text) {
      // 根据文本，计算每个节点的宽
      const canvas = document.getElementById("myCanvas");
      const ctx = canvas.getContext("2d");
      ctx.font = "18px Arial";
      ctx.fillText(text, 0, 0);
      const width = ctx.measureText(text).width;
      return width;
    },
    new_calculate_node_width_height(text) {
      // 根据文本，计算每个节点的高
      const div = document.getElementById("myDiv");
      div.innerText = text;
      return div.clientHeight;
    },
    list_2_tree(list) {
      let map = {},
        node,
        roots = [];
      for (let i = 0; i < list.length; i += 1) {
        map[list[i].id] = i;
        list[i].children = [];
      }
      for (let i = 0; i < list.length; i += 1) {
        node = list[i];
        if (node.parentid !== "") {
          list[map[node.parentid]].children.push(node);
        } else {
          roots.push(node);
        }
      }
      return roots[0];
    },
    create_node(tree_node) {
      let width = this.calculate_node_width_height(tree_node.topic);

      let node_height =
        10 + this.new_calculate_node_width_height(tree_node.topic);
      let node_width =
        (width > this.node_width ? this.node_width : width) +
        this.node_padding_width +
        20;
      let data = {
        width: node_width,
        height: node_height,
        shape: "html",
        id: tree_node.id,
        layer: tree_node.layer,
        parentId: tree_node.parentId,
        data: tree_node.data ? tree_node.data : {},
        children: tree_node.children,

        html() {
          const con = document.createElement("div"); //
          const node = document.createElement("div"); // 节点
          con.style.display = "flex";
          con.style.alignItems = "center";

          node.id = "node@" + tree_node.id;
          node.style.minWidth = node_width + "px";
          node.style.height = node_height + "px";
          // node.style.background = "#428bca";
          node.style.display = "flex";
          node.style.justifyContent = "center";
          node.style.alignItems = "center";
          node.style.textAlign = "left";
          // node.style.border = "1px solid #357ebd";
          node.style.borderRadius = "5px";
          node.style.padding = "5px 10px";
          node.style.wordBreak = "break-all";
          node.style.font = "18px Arial";
          node.style.color = "black";
          node.style.backgroundColor = "white";
          node.style.lineHeight = "25px";
          node.innerText = tree_node.topic;

          con.appendChild(node);
          return con;
        },
      };
      return data;
    },
    create_root_node(tree_node) {
      let width = this.calculate_node_width_height(tree_node.topic);

      let node_height =
        10 + this.new_calculate_node_width_height(tree_node.topic);
      let node_width =
        (width > this.node_width ? this.node_width : width) +
        this.node_padding_width +
        20;

      let data = {
        width: node_width,
        height: node_height,
        shape: "html",
        id: tree_node.id,
        layer: tree_node.layer,
        parentId: tree_node.parentId,
        data: tree_node.data ? tree_node.data : {},
        children: tree_node.children,

        html() {
          const con = document.createElement("div"); //
          const node = document.createElement("div"); // 节点

          con.style.display = "flex";
          con.style.alignItems = "center";

          node.id = "node@" + tree_node.id;
          node.style.minWidth = node_width + "px";
          node.style.height = node_height + "px";
          // node.style.background = "#428bca";
          node.style.display = "flex";
          node.style.justifyContent = "center";
          node.style.alignItems = "center";
          node.style.border = "4px solid #428bca";
          node.style.borderRadius = "5px";
          node.style.padding = "5px 10px";
          node.style.wordBreak = "break-all";
          node.style.font = "18px Arial";
          node.style.textAlign = "left";
          node.style.backgroundColor = "white";
          node.style.color = "black";
          node.style.lineHeight = "25px";
          node.innerText =
            tree_node.topic.length > 0 ? tree_node.topic : "new node";

          con.appendChild(node);
          return con;
        },
      };
      return data;
    },
    modify_node_data(obj) {
      if (obj.children && Array.isArray(obj.children)) {
        for (let i in obj.children) {
          obj.children[i] = this.modify_node_data(obj.children[i]);
        }
      }
      if (parseInt(obj.layer) == 1) {
        return this.create_root_node(obj);
      } else {
        return this.create_node(obj);
      }
    },

    create_edge(source_node, target_node) {
      let data = {
        source: source_node, // 起始节点 id
        target: target_node, // 目标节点 id
        markup: [
          {
            tagName: "path",
            selector: "stroke",
          },
        ],
        attrs: {
          stroke: {
            fill: "none",
            stroke: "rgb(66,139,202)",
            connection: true,
            strokeWidth: 2,
            strokeLinecap: "round",
          },
        },

        router: {
          name: "er",
          args: {
            direction: "R",
            offser: "center",
          },
        },
        connector: "rounded",
      };
      return data;
    },
    traverseTree(n) {
      let d = n.data;
      d.x = n.x + 10;
      d.y = n.y + 10;
      this.graph.addNode(d); // 添加节点
      if (n.parent) {
        this.graph.addEdge(this.create_edge(n.data.id, n.parent.id)); // 添加边
      }
      this.$nextTick(() => {
        this.percent++;
      });
      n.children.forEach(this.traverseTree);
    },
    init_nodes_edges(data) {
      let use_data = JSON.parse(JSON.stringify(data));
      use_data = this.modify_node_data(use_data);

      let result = Hierarchy.mindmap(use_data, {
        direction: "H",
        getHeight(d) {
          return d.height;
        },
        getHGap(d) {
          return 70;
        },
        getVGap() {
          return 20;
        },
        getSide: () => {
          return "right";
        },
      });

      let l = [];
      l.push(result);
      this.percent = 0;
      let num = 0;

      let min_x = 999;
      let min_y = 999;
      let max_x = -110;
      let max_y = -110;

      const updateGraph = () => {
        num++;
        let n = l.shift();
        let d = n.data;
        d.x = n.x + 10;
        d.y = n.y + 10;

        min_x = Math.min(min_x, d.x);
        min_y = Math.min(min_y, d.y);

        max_x = Math.max(max_x, d.x + d.width);
        max_y = Math.max(max_y, d.y + d.height);

        this.graph.addNode(d); // 添加节点
        if (n.parent) {
          this.graph.addEdge(this.create_edge(n.data.id, n.parent.id)); // 添加边
        }
        l = [...l, ...n.children];
        this.percent = (num / this.all_num) * 100;
        if (l.length > 0) {
          setTimeout(updateGraph, 0);
        } else {
          this.water_area = [min_x, max_x, min_y, max_y];
          this.show_proceis_loginss = false;
          if (
            this.is_login &&
            sessionStorage.getItem("exportPng") &&
            sessionStorage.getItem("exportPng") == this.$route.params.pathId
          ) {
            this.download();
            sessionStorage.removeItem("exportPng");
          }
        }
      };
      updateGraph();
      this.graph.centerPoint(0, 0);
    },
    init(data) {
      this.graph = new Graph({
        // 具体参数见官方文档 https://x6.antv.vision/zh/docs/api/graph/graph
        container: document.getElementById("container"),
        scroller: true,
        interacting: false,
        autoResize: true,
        mousewheel: {
          enabled: true,
          modifiers: ["ctrl", "meta"],
        },
        minimap: {
          enabled: true,
          container: document.getElementById("minicontainer"),
        },
      });
      this.init_nodes_edges(this.list_2_tree(data));
    },
  },
};
</script>

<style lang="scss" scoped>
#loading_share {
  height: calc(100vh - 60px);
  width: 100vw;
  position: fixed;
  top: 60px;
  background-color: #ffffff;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999999;
  flex-direction: column;
}
.language-option {
  position: absolute;
  top: 20px;
  right: 20px;
}
.left-header {
  background: linear-gradient(
    90deg,
    rgba(0, 76, 170, 1) 34%,
    rgba(3, 122, 188, 1) 100%
  );
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  .topbar-logo {
    padding-left: 10px;
    padding-right: 17px;
    padding-top: 7px;
    max-height: 100%;
    max-width: 100%;
  }
  .name_logo {
    width: 231px;
    height: 36px;
  }
}
.nofile {
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    font-size: 20px;
  }
}
#label_container2 {
  width: 100%;
}
.header {
  padding: 0 20px;

  .topic {
    height: 50px;

    color: rgb(44, 62, 80);
    font-size: 20px;
    display: flex;
    align-items: center;
  }
  .operate {
    font-size: 16px;
    height: 40px;
    display: flex;
    align-items: center;
    color: #808080;
    justify-content: space-between;
    border-bottom: 1px solid #d1d5de;
  }
  padding-bottom: 10px;
}
.switch_on {
  // margin-top: 1vh;
  margin-top: -10px;
  border-top: 1px solid #d1d5de;
  cursor: pointer;
  &:hover {
    background-color: #f9fafc;
    color: #409eff;
  }
}
.switch_off {
  // margin-top: -10px;
  cursor: pointer;
  &:hover {
    background-color: #f9fafc;
    color: #409eff;
  }
}
#myDiv {
  font: 18px Arial; /*no*/
  max-width: 450px; /*no*/
  line-height: 25px; /*no*/
  word-break: break-all;
  position: absolute;
  visibility: hidden;
}
.el-menu-demo {
  ::v-deep .el-submenu__title {
    height: auto !important;
    line-height: normal !important;
    padding-left: 0;
  }
}
::v-deep .el-submenu__title {
  border-bottom: none !important;
}
::v-deep .is-active {
  border-bottom: none !important;
}
.box_item_l {
  height: 30px;
  border: 1px solid #014caa;
  border-radius: 5px 0 0 5px;
  padding: 6px 14px;
  background-color: #014caa;
  display: flex;
  justify-items: center;
  align-items: center;
}
.box_item_r {
  height: 30px;

  border: 1px solid rgba(219, 219, 219, 1);
  border-radius: 0 5px 5px 0;
  padding: 0 14px;
  display: flex;
  justify-items: center;
  align-items: center;
  .box_font {
    font-size: 18px;
    color: rgba(255, 195, 0, 1);
    font-weight: 700;
  }
}
</style>
<style>
.outstanding_detail_dialog_body {
  pointer-events: auto !important;
  top: 100px;
  right: 50px;
  position: absolute;
}
</style>
<style lang="scss">
.outstanding_detail_dialog {
  pointer-events: none;
}
.outstanding_line {
  margin-bottom: 15px;
  display: flex;
  font-size: 18px;
  justify-content: space-between;
}

.outstanding_dialog_table_class td,
.outstanding_dialog_table_class th.is-leaf {
  border: none;
}

.outstanding_dialog_table_class::before {
  width: 0;
}

.outstanding_dialog_table_class {
  margin-bottom: 10px;
}
.outstanding_dialog_table_class_cell {
  text-align: start;
  font-size: 18px;
  .cell {
    padding: 0 !important;
  }
  .outstanding_name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.outstanding_dialog_color {
  width: 18px;
  height: 18px;
  // background-color: #0090f1;
  margin-right: 10px;
}
.outstanding_detail_dialog_body {
  .el-dialog__body {
    padding-right: 0 !important;
  }
  .el-dialog__header {
    padding-bottom: 0 !important;
  }
}
.outstanding_node {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-left: 25px;
}
</style>
